import React, { useEffect } from 'react'
import PublicationsListItem from './PublicationsListItem'
import { scrollHandler } from '../../utils/functions'

type Props = {
  items: any
}

const PublicationsList: React.FunctionComponent<Props> = ({ items }) => {
  
  useEffect(() => {
    scrollHandler();
  }, [])

  return (<div className="boxes">
    {items.allWordpressWpPublications.edges.map(item => (
      <div className="box" key={item.node.wordpress_id}>
        <PublicationsListItem item={item} />
      </div>
    ))}
  </div>)
}

export default PublicationsList
