import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Works } from '../../interface'
import { Link } from "gatsby"


class PublicationListItem extends React.Component<{item?:Works}> {

	works:any = {};

  constructor(props:any) {
    super(props);
    
    if(this.props.item)
    this.works = this.props.item;
	
  }

	render(){

    let subtitle = this.works.node.acf.subtitle ? '<br />' + this.works.node.acf.subtitle : ''
    let url = '/publications/' + this.works.node.slug + '/'
    let featuredImage = this.works.node.featured_image_src.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
		return (  
      <Link to={url}>
        <img src={featuredImage} alt=""/>
        <p className="post-title">
          {this.works.node.title}{ReactHtmlParser(subtitle)}
        </p>
      </Link>
    )
  }

}


export default PublicationListItem


